<template>
  <v-card class="mx-3 mt-4">
    <v-subheader class="text-caption px-2">历史上的今天</v-subheader>
    <v-container class="pt-0 px-1">
      <v-row v-show="length" justify="center">
        <v-col class="py-0 pl-4">
          <div @click="to(history.href)" class="text-caption" v-for="(history, idx) in historyList.slice(0, 10)" :key="`history-${idx}`">
            {{ history.year }} {{ history.title }}
          </div>
        </v-col>
      </v-row>
      <v-skeleton-loader
        v-show="!length"
        type="list-item-three-line"
      ></v-skeleton-loader>
    </v-container>
  </v-card>
</template>

<script>
import { todayInHistory } from 'network/info'
export default {
  name: 'NotifyCard',
  data: () => ({
    historyList: [],
  }),
  created() {
    this.getHistoryList()
  },
  methods: {
    getHistoryList() {
      todayInHistory().then(res => {
        this.historyList = res
      }).catch(err => {
        console.log(err)
      })
    },
    to(href) {
      location.href = href
    }
  },
  computed: {
    length() {
      return this.historyList.length
    }
  }
}
</script>

<style>

</style>