import Vue from 'vue'
import Vuex from 'vuex'
import * as types from './types'
import { getProfile } from 'network/user'
import { getSchedule } from 'network/schedule'
import dayjs from 'dayjs'
Vue.use(Vuex)

const settings = [
  {
    text: '日历',
    name: 'Calendar',
    show: true
  },
  {
    text: '课程',
    name: 'Schdule',
    show: true
  },
  {
    text: '电费',
    name: 'Electricity',
    show: true
  },
  {
    text: '天气',
    name: 'Weather',
    show: true
  }
]
const state = {
  token: '',
  dataSize: '',
  settings,
  userInfo: {},
  schedules: {},
  arrayEvents: [],
  power: {}
}

export default new Vuex.Store({
  state,
  getters: {

  },
  mutations: {
    [types.SAVETOKEN]: (state, token) => {
      localStorage.token = token
      state.token = token
    },
    clearToken(state) {
      localStorage.removeItem('token')
      state.token = ''
    },
    [types.CLEARTOKEN]: (state) => {
      localStorage.clear()
      state.token = ''
      state.dataSize = ''
      state.settings = settings
      state.userInfo = {}
      state.schedules = {}
      state.arrayEvents = []
      state.power = {}
    },
    [types.SETDATASIZE]: (state, dataSize) => {
      state.dataSize = dataSize
    },
    [types.SAVESETTING]: (state, setting) => {
      localStorage.setting = JSON.stringify(setting)
      state.settings = setting
    },
    [types.SAVEUSERINFO]: (state, userInfo) => {
      localStorage.userInfo = JSON.stringify(userInfo)
      state.userInfo = userInfo
    },
    [types.SAVESCHDULES]: (state, schedules) => {
      localStorage.schedules = JSON.stringify(schedules)
      state.schedules = schedules
    },
    [types.SAVEARRAYEVENTS]: (state, arrayEvents) => {
      localStorage.arrayEvents = JSON.stringify(arrayEvents)
      state.arrayEvents = arrayEvents
    },
    [types.SAVEPOWER]: (state, power) => {
      localStorage.power = JSON.stringify(power)
      state.power = power
    },
  },
  actions: {
    getUserInfo({ commit }) {
      getProfile().then(res => {
        if (res.term_start) {
          res.term_start = dayjs(res.term_start).format('YYYY-MM-DD')
        }
        commit(types.SAVEUSERINFO, res)
      })
    },
    // ToDo: 此处代码过于冗余，有待优化
    getSchdules({ commit }) {
      return new Promise((resolve, reject) => {
        getSchedule().then(res => {
          const arrayEvents = []
          if (res.term_start) {
            const term_start = dayjs(res.term_start).subtract(1, 'day').format('YYYY-MM-DD')
            if (res.schedule) {
              commit(types.SAVESCHDULES, res)
              Vue.prototype.$bus.$emit('SAVESCHDULES')
              res.schedule.forEach(item => {
                // 遍历课程数量
                const day = item.day
                item.weeks.forEach(week => {
                  // 拿到上课周次
                  arrayEvents.push(dayjs(term_start).add(day, 'day').add(week-1, 'week').format('YYYY-MM-DD'))
                })
              })
              commit(types.SAVEARRAYEVENTS, arrayEvents)
              resolve(true)
            } else { resolve('课表信息暂未刷新') }
          } else { resolve('请到首页功能栏设置开学日期') }
        }).catch(err => {

        })
      })
    }
  },
  modules: {
  }
})
