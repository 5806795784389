import { request } from './request'

export function weather() {
  return request({
    url: '/info/weather',
  })
}

export function notify() {
  return request({
    url: '/info/notifys',
  })
}

export function station() {
  return request({
    url: '/info/station',
  })
}

export function users() {
  return request({
    url: '/info/users',
  })
}

export function todayInHistory() {
  return request({
    url: '/grap/todayinhistory',
  })
}
