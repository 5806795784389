<template>
  <v-card class="mx-3 mt-4">
    <v-row no-gutters>
      <v-col>
        <v-subheader class="text-caption px-2"><v-icon size="small">mdi-map-marker</v-icon>黄家湖东路111号</v-subheader>
      </v-col>
      <v-col>
        <v-subheader class="float-right text-caption text--disabled px-2">
          <span :class="`grey ${isDark ? 'darken' : 'lighten'}-2 gray--text px-1`">AQI {{ aqi }}</span>
        </v-subheader>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="2" sm="1" class="pl-2 align-self-center">
        <v-img :src="skyconSrc" max-height="32" max-width="32"></v-img>
      </v-col>
      <v-col cols="2">
        <h1 class="font-weight-regular">{{ temperature }}°</h1>
      </v-col>
      <v-col class="align-self-center">
        <div class="text-caption"><v-icon size="small">mdi-arrow-up-thick</v-icon>{{ maxTemperature }}°</div>
        <div class="text-caption"><v-icon size="small">mdi-arrow-down-thick</v-icon>{{ minTemperature }}°</div>
      </v-col>
      <v-col class="d-flex flex-column pr-2 align-self-center">
        <div class="ml-auto">{{ skyconName }}</div>
        <div class="ml-auto text-caption text--disabled">更新时间 {{ updateTime }}</div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-subheader class="text-caption d-inline-block text-no-wrap text-truncate">{{ forecast_keypoint }}</v-subheader>
      <v-spacer></v-spacer>
    </v-row>
  </v-card>
</template>

<script>
import { weather } from 'network/info'
import { skyconName } from 'common/const'
import dayjs from 'dayjs'

export default {
  name: 'Weather',
  data: () => ({
    updateTime: '',
    forecast_keypoint: '',
    aqi: '',
    temperature: 0,
    minTemperature: 0,
    maxTemperature: 0,
    skyconSrc: '',
    skyconName: '',
  }),
  created() {
    this.reqWeather()
  },
  methods: {
    reqWeather() {
      weather().then(({ server_time, result }) => {
        const { realtime, hourly, daily } = result
        this.updateTime = dayjs(server_time * 1000).format('HH:mm')
        this.forecast_keypoint = result.forecast_keypoint
        this.aqi = realtime.air_quality.description.chn
        this.temperature = realtime.temperature.toFixed(0)
        this.skyconSrc = `http://img.sodion.net/${realtime.skycon}.svg`
        this.skyconName = skyconName[realtime.skycon]
        this.minTemperature = daily.temperature[0].min.toFixed(0)
        this.maxTemperature = daily.temperature[0].max.toFixed(0)
      })
    }
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark
    },
  }
}
</script>

<style scoped>

</style>