import { request } from './request'
import qs from 'qs'

export function login(username, password) {
  return request({
    method: 'POST',
    url: '/auth/login',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      phone: username,
      password
    })
  })
}

export function register(nickname, phone, password) {
  const data = {
    nickname,
    phone,
    password
  }
  return request({
    method: 'POST',
    url: '/auth/register',
    data,
  })
}

export function renewToken() {
  return request({
    method: 'PUT',
    url: '/auth/token',
  })
}

export function changePassWord(oldpass, newpass) {
  const data = {
    password: oldpass,
    new_password: newpass
  }
  return request({
    method: 'PUT',
    url: '/auth/password',
    data,
  })
}

export function logout() {
  return request({
    method: 'delete',
    url: '/auth/unregister'
  })
}
