<template>
  <div class="info-bind">
    <TopBar title="获取电费">
      <BackIcon></BackIcon>
    </TopBar>
    <v-container class="px-5">
      <TopInfo title="绑定寝室号获取" :img="imgPath"></TopInfo>
      <v-autocomplete label="请选择校区" v-model="areaId" item-value="id"
      prepend-icon="mdi-office-building" item-text="name" :items="area"
      color="blue-gray">
      </v-autocomplete>

      <v-autocomplete label="请选择楼栋" no-data-text="还没有选择校区" v-model="buildingId" 
      prepend-icon="mdi-office-building-marker" item-text="name" :items="building" item-value="id"
      color="blue-gray">
      </v-autocomplete>
      <v-autocomplete label="请选择楼层" no-data-text="还没有选择楼栋" v-model="floorId"
      prepend-icon="mdi-home-floor-3" item-text="name" :items="floor" item-value="id"
      color="blue-gray">
      </v-autocomplete>
      <v-autocomplete label="请选择房间" no-data-text="还没有选择楼层" v-model="roomId"
      prepend-icon="mdi-bed" item-text="name" :items="room" item-value="id"
      color="blue-gray">
      </v-autocomplete>
      <v-btn block elevation="0" outlined height="40" class="mt-4" @click="handerBindPower" :loading="loading">立即绑定</v-btn>
    </v-container>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { setChildViewMixin } from "common/mixin"
import { getBuilding, getFloor, getRoom, bindPower } from "network/power"


import building from 'assets/img/feature/building.svg'
import buildingDark from 'assets/img/feature/buildingDark.svg'

export default {
  name: "GetPower",
  mixins: [setChildViewMixin],
  data: () => ({
    loading: false,
    area: [
      {
        "id": "1909041556413111",
        "name": "华东交大理工学院(新智能电表)"
      },
      {
        "id": "1909041747327112",
        "name": "华东交通大学理工学院"
      }
    ],
    areaId: null,
    building: [],
    buildingId: null,
    floor: [],
    floorId: null,
    room: [],
    roomId: null
  }),
  created() {

  },
  methods: {
    handerBindPower() {
      this.reqBindRoom()
    },
    reqGetBuilding(areaId) {
      getBuilding(areaId).then(res => {
        this.building = res.data
      })
    },
    reqGetFloor(buildingId) {
      getFloor(this.areaId, buildingId).then(res => {
        this.floor = res.data
      })
    },
    reqGetRoom(floorId) {
      getRoom(this.areaId, this.buildingId, floorId).then(res => {
        this.room = res.data
      })
    },
    reqBindRoom() {
      bindPower(this.areaId, this.buildingId, this.floorId, this.roomId).then(res => {
        const update_time = dayjs(res.update_time).format('YYYY-MM-DD HH:mm:ss')
        const power = { ...res, name: res.name.slice(10), update_time, }
        this.$store.commit('save_power', power)
        this.$notify({title: '绑定成功', message: '可返回查看电费情况', duration: 1500, type: 'success'})
      }).catch(err => {
        console.log(err)
        this.$notify({title: '绑定失败', message: '未知原因', duration: 1500, type: 'error'})
      })
    }
  },
  computed: {
    imgPath() {
      return this.isDark ? buildingDark : building
    }
  },
  watch: {
    areaId(val) {
      val && this.reqGetBuilding(val)
    },
    buildingId(val) {
      val && this.reqGetFloor(val)
    },
    floorId(val) {
      val && this.reqGetRoom(val)
    }
  }
}
</script>

<style>
</style>