import { request } from './request'

export function getProfile() {
  return request({
    url: '/user/profile',
  })
}

export function postProfile(nickname, phone) {
  const data = {
    nickname,
    phone
  }
  return request({
    method: 'PUT',
    url: '/user/profile',
    data
  })
}

export function postAvatar(data) {
  return request({
    method: 'POST',
    url: '/user/avatar',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function getScores() {
  return request({
    url: '/user/scores',
  })
}

export function bindStu(username, password) {
  const data = {
    stu_num: username,
    stu_pwd: password
  }
  return request({
    method: 'POST',
    url: '/user/bindstu',
    data
  }, 8000)
}

export function bindInfo(term_start, room_id = '') {
  const data = {
    term_start,
    room_id
  }
  return request({
    method: 'POST',
    url: '/user/bindstu',
    data
  })
}
