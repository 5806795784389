<template>
  <div class="permission">
    <TopBar title="用户权限设置">
      <BackIcon></BackIcon>
    </TopBar>
    <v-container class="pa-0">
    <v-text-field append-icon="mdi-magnify" ref="search" v-model="search" label="搜索" autofocus hide-details solo></v-text-field>
    </v-container>
    <v-list nav>
      <v-list-item
        v-for="(user, idx) in searchUser"
        :key="`${idx}-user`"
        @click="editUser(user)"
        dense
      >
      <v-list-item-avatar class="elevation-3"><img :src="user.avatar" /></v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{user.phone}}</v-list-item-title>
        <v-list-item-subtitle>{{`${user.nickname} (${user.permission})`}}</v-list-item-subtitle>
      </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-subtitle-1">
          <v-row no-gutters >编辑权限</v-row>
        </v-card-title>
        
        <v-card-text class="text-center">
          <v-text-field v-model="user.permission" clearable></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">取消</v-btn>
          <v-btn color="primary" text @click="save">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { setChildViewMixin } from 'common/mixin'
import { users } from 'network/info'
import { updatePermission } from 'network/manage'

export default {
  name: 'Permission',
  mixins: [setChildViewMixin],
  data() {
    return {
      search: '',
      user: {
        permission: '',
        phone: '',
      },
      dialog: false,
      users: []
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    editUser(user) {
      this.user.permission = user.permission
      this.user.phone = user.phone
      this.dialog = true
    },
    save() {
      updatePermission(this.user).then(res => {
        this.$notify({title: '权限更新成功',type: 'success',duration: 1500})
        this.getUsers()
        this.dialog = false
      }).catch(({ status }) => {
        if (status === 403) {
          this.$notify({title: '权限更新失败',type: 'error', message: '权限不足', duration: 1500})
        }
        this.dialog = false
      })
    },
    getUsers() {
      users().then(res => {
        this.users = res
      }).catch(err => {
        console.log(err)
      })
    }
  },
  computed: {
    searchUser() {
      return this.users.filter((user) => user.phone.includes(this.search) || user.nickname.includes(this.search))
    }
  },
}
</script>

<style scoped>
</style>