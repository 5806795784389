import { request } from './request'

export function getSchedule() {
  return request({
    url: '/schedule',
  })
}

export function refresh() {
  return request({
    url: '/schedule/refresh'
  })
}
