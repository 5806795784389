import { request } from './request'

export function getBuilding(area_id) {
  const data = {
    area_id
  }
  return request({
    method: 'POST',
    url: '/power/building',
    data
  })
}

export function getFloor(area_id, building_id) {
  const data = {
    area_id,
    building_id
  }
  return request({
    method: 'POST',
    url: '/power/floor',
    data
  })
}

export function getRoom(area_id, building_id, floor_id) {
  const data = {
    area_id,
    building_id,
    floor_id
  }
  return request({
    method: 'POST',
    url: '/power/room',
    data
  })
}

export function bindPower(area_id, building_id, floor_id, room_id) {
  const data = {
    area_id,
    building_id,
    floor_id,
    room_id
  }
  return request({
    method: 'POST',
    url: '/power/bind_power',
    data
  })
}

export function getPower() {
  return request({
    method: 'GET',
    url: '/power/get_power?force=true',
  })
}

