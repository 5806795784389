<template>
  <v-card class="mx-3 mt-4">
    <v-subheader class="text-caption px-2">通知</v-subheader>
    <v-container class="pt-0 px-1">
      <v-row v-show="length" justify="center">
        <v-col class="py-0 pl-4">
          <div class="text-caption" v-for="(msg, idx) in msgs" :key="`notify-${idx}`">
            • {{ msg }}
          </div>
        </v-col>
      </v-row>
      <v-skeleton-loader
        v-show="!length"
        type="list-item-three-line"
      ></v-skeleton-loader>
    </v-container>
  </v-card>
</template>

<script>
import { notify } from 'network/info'
export default {
  name: 'NotifyCard',
  data: () => ({
    msgs: [],
  }),
  created() {
    this.getNotifys()
  },
  methods: {
    notifylist() {
      console.log('---')
    },
    getNotifys() {
      notify().then(res => {
        if (res) {
          const arr = res.filter(notify => notify !== null)
          arr.length ? this.msgs = arr : this.msgs = ['暂无通知']
        } else {
          this.msgs = ['暂无通知']
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  computed: {
    length() {
      return this.msgs.length
    }
  }
}
</script>

<style>

</style>