<template>
  <div class="station">
    <TopBar title="广播站设置">
      <BackIcon></BackIcon>
    </TopBar>
    <v-container class="px-5">
      <div v-for="(icon, i) in icons" :key="`${i}-station`">
        <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>
        <v-list-item :key="`${i}-icon`" class="px-0">
          <v-list-item-icon class="my-0 py-3 mr-2 handle">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="station[keys[i]]"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action @click="click(i)">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
      </div>
      <v-row no-gutters>
        <v-btn
        elevation="0"
        outlined
        height="40"
        @click="submitStation"
        :loading="loading"
        class="mt-2"
        block
        >提交</v-btn>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-subtitle-1">
          <v-row no-gutters >编辑广播站</v-row>
        </v-card-title>
        
        <v-card-text class="text-center">
          <v-textarea :label="label[idx]" v-model="inputVal" clearable counter></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">取消</v-btn>
          <v-btn color="primary" text @click="save">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { setChildViewMixin } from 'common/mixin'
import { station } from 'network/info'
import { manageStation } from 'network/manage'

export default {
  name: 'Station',
  mixins: [setChildViewMixin],
  data() {
    return {
      icons: ['mdi-music', 'mdi-message-minus-outline', 'mdi-account-check-outline'],
      label: ['歌曲地址', '内容', '点歌人'],
      drag: false,
      loading: false,
      dialog: false,
      station: { url: '', content: '', name: '' },
      keys: ['url', 'content', 'name'],
      idx: 0,
      inputVal: ''
    }
  },
  created() {
    this.getStation()
  },
  methods: {
    click(idx) {
      this.dialog = true
      this.idx = idx
      this.inputVal = this.station[this.keys[this.idx]]
    },
    submitStation() {
      manageStation(this.station).then(res => {
        this.$notify({title: '提交成功',type: 'success',duration: 1500})
      }).catch(({ status }) => {
        if (status === 403) {
          this.$notify({title: '提交失败',type: 'error', message: '权限不足', duration: 1500})
        }
      })
    },
    save() {
      const prop = this.keys[this.idx]
      this.station[prop] = this.inputVal
      this.dialog = false
    },
    getStation() {
      station().then(res => {
        if (res) {
          this.station = {
            url: `http://music.163.com/song?id=${res.songid}`,
            content: res.content,
            name: res.name
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  computed: {
  }
}
</script>

<style scoped>
</style>