<template>
  <div class="cardset">
    <TopBar title="卡片设置">
      <BackIcon></BackIcon>
    </TopBar>
    <v-container class="px-5">
      <TopInfo :title="title" :img="imgPath"></TopInfo>
      <div class="text-subtitle-1 grey--text">选择主页卡片是否展开</div>
      <draggable handle=".handle" v-bind="dragOptions" v-model="settings" @start="drag=true" @end="drag=false">
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <div v-for="(setting, i) in settings" :key="`${i}-setting`">
            <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>
            <v-list-item :key="`${i}-${setting.text}`" class="px-0">
              <v-list-item-icon class="my-0 py-3 mr-2 handle">
                <v-icon>mdi-menu</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="setting.text"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <el-switch v-model="setting.show"></el-switch>
              </v-list-item-action>
            </v-list-item>
          </div>
        </transition-group>
      </draggable>
    </v-container>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { setChildViewMixin } from 'common/mixin'
import set from 'assets/img/set.svg'
import setDark from 'assets/img/setDark.svg'
export default {
  name: 'CardSet',
  mixins: [setChildViewMixin],
  components: {
    draggable
  },
  data() {
    return {
      title: '卡片设置',
      drag: false
    }
  },
  computed: {
    settings: {
      get() {
        return this.$store.state.settings
      },
      set(settings) {
        this.$store.commit('save_setting', settings)
      }
    },
    imgPath() {
      return this.isDark ? setDark : set
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
      };
    }
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  background: rgb(243, 243, 243);
}
</style>