<template>
  <v-card class="mx-3 mt-4" min-height="50">
    <v-subheader class="text-caption px-2">电费情况</v-subheader>
    <v-container fluid fill-height class="py-0 px-1">
      <v-row justify="center">
        <v-col class="py-0 pl-4" v-if="power.surplus">
          <div class="text-subtitle">
            <span>{{ power.name }}</span>
            <span class="right">{{ power.surplus }}度</span>
          </div>
          <div class="text-caption mb-2">更新时间 {{ power.update_time }}</div>
        </v-col>
        <v-col v-else  class="pt-0 mb-2">
          <div class="text-center text-caption">请先到首页功能栏获取电费</div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import { getPower } from "network/power"
export default {
  name: 'Electricity',
  data: () => ({
    surplus: '10.5',
    updateTime: dayjs().format('MM-DD HH:mm:ss'),
    value: 120,
  }),
  created() {
    this.reqPower()
  },
  methods: {
    reqPower() {
      getPower().then(res => {
        const update_time = dayjs(res.update_time).format('MM-DD HH:mm:ss')
        const power = { ...res, name: res.name.slice(10), update_time }
        this.$store.commit('save_power', power)
      }).catch(err => err )
    }
  },
  computed: {
    ...mapState({
      power: state => state.power,
    })
  }
}
</script>

<style>
.right {
  float: right;
}
</style>