<template>
  <div class="home" v-touch="{
    down: () => load()
  }">
    <keep-alive exclude="GetScore">
      <router-view></router-view>
    </keep-alive>
    <div v-if="$route.path === '/home'">
    <TopBar title="主页" ref="topbar">
      <SetDark slot="right"></SetDark>
    </TopBar>
    <v-row no-gutters justify="center" v-show="show"><v-btn icon loading raised></v-btn></v-row>
      <v-container fluid class="pa-0">
        <NotifyCard></NotifyCard>
        <FeatureCard></FeatureCard>
        <StationCard></StationCard>
        <HistoryCard></HistoryCard>
        <OverFlowY></OverFlowY>
      </v-container>
    </div>
  </div>
</template>

<script>
import TopBar from 'components/common/TopBar'
import SetDark from 'components/common/SetDark'
import NotifyCard from './cards/NotifyCard'
import FeatureCard from './cards/FeatureCard'
import StationCard from './cards/StationCard'
import HistoryCard from './cards/HistoryCard'
import { isLargeScreenMixin } from 'common/mixin'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  mixins: [isLargeScreenMixin],
  components: {
    TopBar,
    SetDark,
    NotifyCard,
    FeatureCard,
    StationCard,
    HistoryCard
  },
  data: () => ({
    show: false,
  }),
  methods: {
    load() {
      this.show = true
      setTimeout(() => {
        this.show = false
      }, 1500)
    },
  },
  computed: {
    ...mapState({
      settings: state => state.settings
    }),
  }
}
</script>

<style scoped>

</style>