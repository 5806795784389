<template>
    <v-card class="mx-3 mt-4" min-height="50">
      <v-subheader class="text-caption px-2">今日课程</v-subheader>
      <div v-if="res.length > 0" class="pb-1">
        <v-alert
        border="left"
        colored-border
        color="warning"
        class="mb-2 py-0"
        dense
        v-for="(sch, idx) in res"
        :key="`alert-${idx}`"
      >
        <div class="clear-fix">
          {{ sch.name }}<span style="float: right">{{ sch.position }}</span>
        </div>
        <div class="clear-fix caption">
          {{ sch.teacher
          }}<span style="float: right">{{ sch.sections }}节</span>
        </div>
        </v-alert>
      </div>
      <div v-else>
      <v-container fluid fill-height class="pt-0 px-1">
      <v-row justify="center">
        <v-col class="pt-0">
          <div class="text-caption text-center">
            {{ schedules.term_start ? '今日没有课程安排' : msg }}
          </div>
        </v-col>
      </v-row>
    </v-container>
      </div>
    </v-card>
</template>

<script>
import dayjs from "dayjs"
import { getSchedule } from 'network/schedule'
import { mapState, mapActions } from 'vuex'

const today = dayjs().format('YYYY-MM-DD')

export default {
  name: "Schdule",
  data: () => ({
    msg: '',
    res: [],
    calendarDate: today,
  }),
  created() {
    if (!this.schedules.hasOwnProperty('schedule') || !this.schedules.term_start) {
      this.reqSchdule().then(res => {
        this.msg = res
      })
    }
  },
  mounted() {
    this.$bus.$on('SAVESCHDULES', () => {
      this.getSchdule()
    })
    this.getSchdule()
  },
  methods: {
    ...mapActions({
      reqSchdule: 'getSchdules'
    }),
    getSchdule() {
      const schedule = this.schedules["schedule"]
      // 计算日期差多少天
      let diff = dayjs(this.date).diff(this.schedules["term_start"], "day")
      // 今天是第几周
      const week = parseInt(diff / 7) + 1
      // 今天是周几
      const dayOfWeek = dayjs(this.date).day() 
      // 周日返回值为0，单独处理
      const day = dayOfWeek != 0 ? dayOfWeek : 7
      if (schedule) {
        this.res = schedule
        // 过滤掉课程中不包含今天周几的课程
        .filter((item) => item.day == day)
        // 过滤掉课程中不包含该周的课
        .filter(({ weeks }) => weeks.find((val) => val === week))
      }
    }
  },
  computed: {
    ...mapState({
      schedules: state => state.schedules
    }),
    date() {
      return this.calendarDate === today ? today : this.calendarDate
    }
  },
  watch: {
    calendarDate(old, newVal) {
      this.getSchdule()
    }
  }
}
</script>

<style>
</style>