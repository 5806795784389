<template>
  <div class="info-bind">
    <TopBar title="获取课表">
      <BackIcon></BackIcon>
    </TopBar>
    <v-container class="px-5">
      <TopInfo title="设置开学日期获取" :img="imgPath"></TopInfo>
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="选择开学日期"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            color="blue-gray"
            messages="开学的第一周的周一，未设置正确会导致课表显示异常"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          scrollable
          no-title
          locale="zh-cn"
          color="primary"
          :day-format="dayformat"
        >
          <v-spacer></v-spacer>
          <v-btn text @click="modal = false"> 取消 </v-btn>
          <v-btn text color="primary" @click="$refs.dialog.save(date)">
            确定
          </v-btn>
        </v-date-picker>
      </v-dialog>

      <v-row no-gutters>
        <v-btn
        elevation="0"
        outlined
        height="40"
        @click="handerBindInfo"
        :loading="loading"
        class="mt-2"
        block
        >设置开学日期并获取课表</v-btn
      >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { setChildViewMixin } from "common/mixin"
import { bindInfo } from "network/user"
import { refresh } from 'network/schedule'
import dayjs from "dayjs"

import schedule from 'assets/img/feature/schedule.svg'
import scheduleDark from 'assets/img/feature/scheduleDark.svg'

export default {
  name: "GetSchedule",
  mixins: [setChildViewMixin],
  data: () => ({
    loading: false,
    modal: false,
    date: dayjs().format("YYYY-MM-DD"),
  }),
  created() {
    const term_start = this.$store.state.userInfo.term_start
    term_start ? (this.date = term_start) : ``
  },
  methods: {
    handerBindInfo() {
      console.log(this.date)
      this.loading = true
      this.reqBindInfo()
    },
    reqBindInfo() {
      bindInfo(this.date).then((res) => {
        this.reqRefresh()
        this.loading = false
      })
    },
    reqRefresh() {
      refresh().then(res => {
        const opts = { title: '课表获取成功', type: 'success', duration: 2000 }
        this.$notify(opts)
        this.$store.dispatch('getSchdules')
      }).catch(err => {
        const opts = { title: '获取课表失败', message: '请稍后重试', type: 'error' }
        this.$notify(opts)
      })
    },
    dayformat(date) {
      return dayjs(date).format('D')
    }
  },
  computed: {
    imgPath() {
      return this.isDark ? scheduleDark : schedule
    }
  }
}
</script>

<style>
</style>