<template>
  <div class="bottom-bar-item" @click="switchView">
    <v-icon v-show="isActive">{{ activeIco }}</v-icon>
    <v-icon v-show="!isActive">{{ deActiveIco }}</v-icon>
    <span class="text-caption">{{ desc }}</span>
  </div>
</template>

<script>
export default {
  name: 'BottomBarItem',
  props: {
    path: String,
    desc: {
      type: String,
      default: '默认'
    },
    activeIco: {
      type: String,
      default: 'mdi-white-balance-sunny'
    },
    deActiveIco: {
      type: String
    }
  },
  methods: {
    switchView() {
      this.$router.replace(this.path).catch(err => err)
    }
  },
  computed: {
    isActive() {
      return this.$route.path.includes(this.path)
    }
  }
}
</script>

<style scoped>
.bottom-bar-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bottom-bar-item span {
  font-size: 14px;
}
</style>