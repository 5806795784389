<template>
  <v-card class="mx-3 mt-4">
    <v-subheader class="text-caption px-2">广播站</v-subheader>
    <v-container class="pt-0 px-1">
      <v-row no-gutters>
        <v-col cols="3">
          <meting-js v-if="station.url" lock="true" :auto="station.url" mini="true"></meting-js>
        </v-col>
        <v-col>
          <div class="text-body-2" v-text="station.content"></div>
          <span class="float-right text-body-2 pr-3" v-text="station.name"></span>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { station } from 'network/info'
export default {
  name: 'StationCard',
  data: () => ({
    text: '点歌人：Senku，一首《晚风》送给17软工34班全体同学，祝大家前程似锦，毕业快乐。',
    isShow: false,
    station: {}
  }),
  created() {
    this.getStation()
  },
  activated() {
    this.getStation()
  },
  methods: {
    more() {
      console.log('more')
      this.$router.push('/home/stationlist')
    },
    getStation() {
      station().then(res => {
        if (res.hasOwnProperty('songid')) {
          this.station = {
            url: `http://music.163.com/song?id=${res.songid}`,
            content: res.content,
            name: `--${res.name}`
          }
          this.isShow = true
        } else {
          this.station = {
            url: 'http://music.163.com/song?id=1441758494',
            content: '南昌交通学院广播站',
            name: '--Senku'
          }
          this.isShow = true
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>

<style>

</style>