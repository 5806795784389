<template>
  <div class="profile">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <div v-if="$route.path === '/profile'">
    <TopBar title="我的">
      <SetDark slot="right"></SetDark>
    </TopBar>
    <v-container fluid class="pa-0">
      <User></User>
      <SetCard></SetCard>
      <!-- <InfoCard></InfoCard> -->
      <AdminCard v-if="permission > 5"></AdminCard>
      <AuthCard></AuthCard>
      <OverFlowY></OverFlowY>
    </v-container>
    </div>
  </div>
</template>

<script>
import TopBar from "components/common/TopBar"
import SetDark from 'components/common/SetDark'
import { isLargeScreenMixin } from 'common/mixin'
import { mapState } from 'vuex'

import User from "./avatar/User";
import SetCard from './cards/SetCard'
import InfoCard from './cards/InfoCard'
import AuthCard from './cards/AuthCard'
import AdminCard from './cards/AdminCard'

export default {
  name: "Profile",
  mixins: [isLargeScreenMixin],
  components: {
    TopBar,
    SetDark,
    User,
    SetCard,
    InfoCard,
    AuthCard,
    AdminCard
  },
  data: () => ({
  }),
  methods: {
    back() {
      console.log('back')
    }
  },
  computed: {
    ...mapState({
      permission: state => state.userInfo.permission
    }),
  }
};
</script>

<style scoped>
</style>