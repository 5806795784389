export const rulesForPhone = [
  (value) => !!value || "必须",
  (value) => (value || "").length != 10 || "请输入11位手机号",
  (value) => {
    const pattern = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/
    return pattern.test(value) || "无效的手机号"
  },
]

export const rulesForPassWord = [
  (value) => !!value || "必须",
  (value) => (value || "").length >= 8 || "密码至少8个字符",
  (value) => {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\S])[a-zA-Z0-9\S]{8,16}$/
    return pattern.test(value) || "密码至少一个大写、一个小写字母、一个数字,且长度为8到16位"
  }
]

export const skyconName = {
  'CLEAR_DAY': '晴朗',
  'CLEAR_NIGHT': '晴朗',
  'PARTLY_CLOUDY_DAY': '多云',
  'PARTLY_CLOUDY_NIGHT': '多云',
  'CLOUDY': '阴天',
  'LIGHT_HAZE': '轻度雾霾',
  'MODERATE_HAZE': '中度雾霾',
  '	HEAVY_HAZE': '重度雾霾',
  'LIGHT_RAIN': '小雨',
  'MODERATE_RAIN': '中雨',
  'HEAVY_RAIN': '大雨',
  'STORM_RAIN': '暴雨',
  'FOG': '雾',
  'LIGHT_SNOW': '小雪',
  'MODERATE_SNOW': '中雪',
  'STORM_SNOW': '暴雪',
  'DUST': '浮尘',
  'SAND': '沙尘',
  'WIND': '大风',
}