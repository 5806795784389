<template>
  <v-row style="height: 80%" no-gutters v-if="!$store.state.token">
    <v-col align-self="center">
      <v-container>
        <v-card class="mx-auto" elevation="0" outlined :loading="loading">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ currentTitle }}</span>
          </v-card-title>

          <v-window v-model="step">

            <v-window-item :value="1">
              <v-card-text>
                <v-text-field
                  label="手机号"
                  value=""
                  v-model="phone"
                  :rules="rulesForPhone"
                  ref="phone"
                ></v-text-field>
                <span class="caption grey--text text--darken-1">
                  请输入登录Na+课表的账号
                </span>
                <v-text-field
                  label="密码"
                  :type="inputType"
                  v-model="password"
                  :rules="rulesForPassWord"
                >
                <template v-slot:append><v-icon v-text="iconEye" @click="change"></v-icon></template>
                </v-text-field>
                <span class="caption grey--text text--darken-1">
                  请输入登录Na+课表的密码
                </span>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-btn text @click="step++" color="primary"> 创建账号 </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  depressed
                  @click="handerLogin"
                >
                  登录
                </v-btn>
              </v-card-actions>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-text-field
                  label="昵称"
                  value=""
                  v-model="nickname"
                ></v-text-field>
                <span class="caption grey--text text--darken-1">
                  为你的「Na+」课表账户取个好听的名字吧🙂️
                </span>
                <v-text-field
                  label="手机号"
                  value=""
                  v-model="phone"
                  :rules="rulesForPhone"
                ></v-text-field>
                <span class="caption grey--text text--darken-1">
                  该手机号码将作为登录Na+课表的账户
                </span>

                <v-text-field
                  label="密码"
                  :type="inputType"
                  v-model="password"
                  :rules="rulesForPassWord"
                >
                <template v-slot:append><v-icon v-text="iconEye" @click="change"></v-icon></template>
                </v-text-field>
                <v-text-field
                  label="确认密码"
                  :type="inputType"
                  v-model="confirmPassword"
                  :rules="rulesForPassWord"
                >
                <template v-slot:append><v-icon v-text="iconEye" @click="change"></v-icon></template>
                </v-text-field>
                <span class="caption grey--text text--darken-1">
                  使用 8 个或更多字符（字母、数字和符号的组合）
                </span>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-btn text @click="step--" color="primary">  登录已有账号 </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  depressed
                  @click="handerRegister"
                >
                  注册
                </v-btn>
              </v-card-actions>
            </v-window-item>
          </v-window>
        </v-card>
      </v-container>
    </v-col>
    <v-footer :fixed="true">
      <v-row no-gutters justify="center">
        <v-subheader>©️2020 Na+课表 <span class="ml-2" @click="tobeian">赣ICP备20002556号-2</span>
        </v-subheader>
      </v-row>
    </v-footer>
  </v-row>
</template>
<script>
import { login, register, renewToken } from 'network/auth'
import { rulesForPhone, rulesForPassWord } from 'common/const'
import { eyeChangeMixin } from 'common/mixin'
import { mapState } from 'vuex'

export default {
  name: 'Login',
  mixins: [eyeChangeMixin],
  data: () => ({
    step: 1,
    nickname: '',
    phone: null,
    password: null,
    confirmPassword: null,
    loading: false,
    // 输入框校验函数
    rulesForPhone,
    rulesForPassWord,
  }),
  created() {
    if (this.token) {
      renewToken().then(res => {
        this.$store.commit('save_token', res.token)
        this.$router.push('/today')
      }).catch(err => {
        store.commit('clear_token')
        this.$router.replace('/login')
      })
    }
  },
  methods: {
    /**
     * desc: 输入框合法性校验
     * params func: 接收一个函数去调用
     */
    check(func) {
      const checkPhoneRes = rulesForPhone[2](this.phone)
      const checkPassRes = rulesForPassWord[2](this.password)
      if (checkPhoneRes === true && checkPassRes === true) {
        func()
      } else {
        checkPhoneRes === true ?  `` : this.$notify({
            title: '输入提示',
            message: checkPhoneRes,
            type: 'warning',
            duration: 1500,
        })
        
        checkPassRes === true ? `` : this.$notify({
            title: '输入提示',
            message: checkPassRes,
            type: 'warning',
            duration: 1500,
        })
        this.loading = false
      }
    },
    // 处理登录
    handerLogin() {
      this.loading = true
      this.check(this.reqLogin)
    },
    // 发起登录请求
    reqLogin() {
      login(this.phone, this.password)
      .then(res => {
        this.$store.commit('save_token', res.token)
        let redirect = decodeURIComponent(this.$route.query.redirect || '/today')
        this.loading = false
        // Fixed:解决token失效后重新登录的白屏问题
        redirect === '/login' ? redirect = '/today' : ``
        this.$router.replace(redirect)
        this.$notify({
          title: '登录成功',
          type: 'success',
          duration: 1500,
        })
      })
      .catch(({ status }) => {
        if (status === 404) {
          this.loading = false
          this.$notify({
            title: '登陆失败',
            message: '该用户未注册',
            type: 'error',
            duration: 1500,
          })
        }
        if (status === 400) {
          this.$notify({
            title: '登录失败',
            message: '账户或密码不正确',
            type: 'error',
            duration: 1500,
          })
          this.loading = false
        }
      })
    },
    // 处理注册
    handerRegister() {
      this.loading = true
      if (this.password === this.confirmPassword) {
        this.check(this.reqRegister)
      } else {
        this.$notify({
          title: '输入错误',
          message: '密码不一致',
          type: 'warning',
          duration: 1500,
        })
        this.loading = false
      }
    },
    // 发起注册请求
    reqRegister() {
      register(this.nickname, this.phone, this.password)
        .then(res => {
          this.reqLogin()
          this.$notify({
            title: '注册成功',
            message: '自动登录中...',
            type: 'success',
            duration: 1500,
          })
        })
        .catch(({status, data}) => {
          if (status === 409) {
            this.loading = false
            this.$notify({
              title: '注册失败',
              message: '该用户已注册',
              type: 'error',
              duration: 1500,
            })
          }
        })
    },
    // 备案链接
    tobeian() {
      location.href = 'https://beian.miit.gov.cn/'
    }
  },
  computed: {
    ...mapState({
      token: state => state.token
    }),
    currentTitle() {
      switch (this.step) {
        case 1:
          return "登录"
        case 2:
          return "创建您的 Na+ 帐号"
      }
    },
  },
}
</script>