<template>
  <div class="get-score">
    <TopBar title="成绩查询">
      <BackIcon></BackIcon>
    </TopBar>
    <v-tabs v-model="tab" show-arrows center-active slider-color="black" color="gray">
      <v-tab :ripple="false" v-for="item in items" :key="item.tab">{{ item.tab }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-data-table
          disable-sort
          :items-per-page="100"
          :fixed-header="true"
          mobile-breakpoint="100"
          :headers="headers"
          :items="scores[item.content]"
          hide-default-footer
          class="elevation-1 test"
          no-data-text="暂无成绩相关信息"
        >
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <v-overlay :value="overlay" v-show="overlay">
      <v-progress-circular indeterminate size="64">
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { setChildViewMixin } from "common/mixin"
import { getScores } from "network/user"
export default {
  name: "GetScore",
  mixins: [setChildViewMixin],
  data: () => ({
    tab: 0,
    overlay: true,
    headers: [
      { text: '课程名', value: 'kcm', align: 'left', },
      // { text: '类别', value: 'kclb', align: 'left', },
      { text: '考核', value: 'khfs', align: 'center', },
      { text: '学分', value: 'xf', align: 'left', },
      { text: '绩点', value: 'jd', align: 'left', },
      { text: '成绩', value: 'cj', align: 'left', },
    ],
    scores: {},
    items: [
        { tab: '大一上学期' },
        { tab: '大一下学期' },
        { tab: '大二上学期' },
        { tab: '大二下学期' },
        { tab: '大三上学期' },
        { tab: '大三下学期' },
        { tab: '大四上学期' },
        { tab: '大四下学期' },
    ],
  }),
  created() {
    this.reqScore()
  },
  methods: {
    reqScore() {
      getScores().then(res => {
        this.scores = res
        const keys = Object.keys(this.scores)
        this.items.forEach((item, index) => {
          item.content = keys[index]
        })
        this.tab = keys.length - 1
        this.overlay = false
      }).catch(err => {
        this.overlay = false
        this.$notify({
          title: '查询失败',
          message: '该用户未绑定教务账户',
          type: 'error',
          duration: 1500,
        })
      })
    }
  },
}
</script>

<style scoped>
.test >>> table .text-left,
.test >>> table .text-center{
  padding: 0 10px;
  font-size: 0.7rem;
}
</style>