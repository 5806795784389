import { request } from './request'

export function manageNotify(notifyList) {
  const data = {
    notifyList
  }
  return request({
    url: '/manage/notifys',
    method: 'POST',
    data
  })
}

export function manageStation({ url, content, name }) {
  const data = {
    url,
    content,
    name
  }
  return request({
    url: '/manage/station',
    method: 'POST',
    data
  })
}

export function updatePermission({ phone, permission }) {
  const data = {
    phone,
    permission,
  }
  return request({
    url: '/manage/permission',
    method: 'PUT',
    data
  })
}
