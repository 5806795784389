<template>
  <div class="cardset">
    <TopBar title="设置通知">
      <BackIcon></BackIcon>
    </TopBar>
    <v-container class="px-5">
      <draggable handle=".handle" v-bind="dragOptions" v-model="notifys" @start="drag=true" @end="drag=false">
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <div v-for="i in 4" :key="`${i}-notifys`">
            <v-divider v-if="i !== 1" :key="`${i}-divider`"></v-divider>
            <v-list-item :key="`${i}-icon`" class="px-0">
              <v-list-item-icon class="my-0 py-3 mr-2 handle">
                <v-icon>mdi-menu</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="notifys[i-1] ? notifys[i-1] : '' "></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action @click="click(i-1)">
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-list-item-action>
            </v-list-item>
          </div>
        </transition-group>
      </draggable>
      <v-row no-gutters>
        <v-btn
        elevation="0"
        outlined
        height="40"
        @click="submitNotify"
        :loading="loading"
        class="mt-2"
        block
        >保存</v-btn
      >
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-subtitle-1">
          <v-row no-gutters >编辑通知</v-row>
        </v-card-title>
        
        <v-card-text class="text-center">
          <v-textarea v-model="inputVal" clearable counter></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">取消</v-btn>
          <v-btn color="primary" text @click="save">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { setChildViewMixin } from 'common/mixin'
import { notify } from 'network/info'
import { manageNotify } from 'network/manage'

export default {
  name: 'PlatNotify',
  mixins: [setChildViewMixin],
  components: {
    draggable,
  },
  data() {
    return {
      drag: false,
      loading: false,
      dialog: false,
      notifys: [],
      idx: 0,
      inputVal: ''
    }
  },
  created() {
    this.getNotifys()
  },
  methods: {
    click(idx) {
      this.dialog = true
      this.idx = idx
      this.inputVal = this.notifys[idx]
    },
    submitNotify() {
      manageNotify(this.notifys).then(res => {
        this.$notify({title: '设置成功',type: 'success',duration: 1500})
      }).catch(({ status }) => {
        if (status === 403) {
          this.$notify({title: '提交失败',type: 'error', message: '权限不足', duration: 1500})
        }
      })
    },
    save() {
      this.notifys[this.idx] = this.inputVal
      this.dialog = false
    },
    getNotifys() {
      notify().then(res => {
        if (res) {
          this.notifys = res
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
      };
    }
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  background: rgb(243, 243, 243);
}
</style>