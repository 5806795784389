<template>
  <div class="today" v-touch="{
    down: () => load()
  }">
    <keep-alive exclude="GetScore">
      <router-view></router-view>
    </keep-alive>
    <div v-if="$route.path === '/today'">
    <TopBar title="今日" ref="topbar">
      <SetDark slot="right"></SetDark>
    </TopBar>
    <v-row no-gutters justify="center" v-show="show"><v-btn icon loading raised></v-btn></v-row>
      <v-container fluid class="pa-0">
        <div v-for="(setting, i) in settings" :key="`${i}-setting`">
          <component :is="setting.name" v-if="setting.show" @calendarClick="changeSchduleDate" :ref="setting.name"></component>
        </div>
        <OverFlowY></OverFlowY>
      </v-container>
    </div>
  </div>
</template>

<script>
import TopBar from 'components/common/TopBar'
import SetDark from 'components/common/SetDark'
import Calendar from './cards/Calendar'
import Schdule from './cards/Schdule'
import Electricity from './cards/Electricity'
import Weather from './cards/Weather'
import { isLargeScreenMixin } from 'common/mixin'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Today',
  mixins: [isLargeScreenMixin],
  components: {
    TopBar,
    SetDark,
    Calendar,
    Schdule,
    Electricity,
    Weather
  },
  data: () => ({
    show: false,
  }),
  methods: {
    ...mapActions({
      reqSchdule: 'getSchdules',
      reqUserInfo: 'getUserInfo',
    }),
    changeSchduleDate(date) {
      this.$refs.Schdule[0].calendarDate = date
    },
    load() {
      this.show = true
      setTimeout(() => {
        this.reqSchdule().then(res => {
        if (res === true) {
            this.show = false
          }
        })
        this.reqUserInfo()
        this.show = false
      }, 1500)
    },
  },
  computed: {
    ...mapState({
      settings: state => state.settings
    }),
  }
}
</script>

<style scoped>

</style>